import { organisationLocations } from '../constants';

export const shouldLocationHaveExtra = location => {
  return location === organisationLocations.OTHER;
};

export const extractLocationExtra = location => {
  if (!location) return '';
  const locationExtraType = location.includes(organisationLocations.OTHER);

  if (locationExtraType) {
    return location
      .replace(locationExtraType, '')
      .replace(/^\s-+\s/, '')
      .trim();
  }
  return '';
};
