const modulesCategories = {
  ALCOHOL_AWARENESS: 'ALCOHOL_AWARENESS',
  ALCOHOL_IDENTIFICATION: 'ALCOHOL_IDENTIFICATION',
  MANAGEMENT_OF_ALCOHOL: 'MANAGEMENT_OF_ALCOHOL',
  POVERTY_AWARENESS: 'POVERTY_AWARENESS',
  POVERTY_IDENTIFICATION_AND_SUPPORT: 'POVERTY_IDENTIFICATION_AND_SUPPORT',
  POVERTY_PROOFING_INTERVENTIONS: 'POVERTY_PROOFING_INTERVENTIONS',
  TOBACCO_AWARENESS: 'TOBACCO_AWARENESS',
  TOBACCO_IDENTIFICATION: 'TOBACCO_IDENTIFICATION',
  TOBACCO_TREATMENT: 'TOBACCO_TREATMENT',
  TOBACCO_CONTROL: 'TOBACCO_CONTROL'
};

export const tpOrgTypes = {
  PRIMARY_CARE_GP_SERVICES_AND_COMMUNITY_NURSING:
    'Primary Care - GP services and Community Nursing',
  SOCIAL_CARE_COMMUNITY_AND_SOCIAL_SERVICES:
    'Social Care - community and social services',
  SECONDARY_CARE_NHS_TRUSTS_AND_SERVICES:
    'Secondary Care - NHS Trusts and services',
  MENTAL_HEALTH_TRUSTS_AND_SERVICES: 'Mental Health Trusts and services',
  VOLUNTARY_COMMUNITY_AND_SOCIAL_ENTERPRISE_ORGANISATIONS:
    'voluntary, community and social enterprise organisations',
  LOCAL_AUTHORITY_AND_SERVICES: 'Local Authority and Services',
  NHS_ENGLAND: 'NHS England',
  EDUCATION_ORGANISATION: 'Education Organisation',
  OTHER_PLEASE_STATE: 'Other - please state'
};

export const employerOrgTypes = {
  PRIMARY_CARE_GP_SERVICES_AND_COMMUNITY_NURSING:
    'Primary Care - GP services and Community Nursing',
  SOCIAL_CARE_COMMUNITY_AND_SOCIAL_SERVICES:
    'Social Care - community and social services',
  SECONDARY_CARE_NHS_TRUSTS_AND_SERVICES:
    'Secondary Care - NHS Trusts and services',
  MENTAL_HEALTH_TRUSTS_AND_SERVICES: 'Mental Health Trusts and services',
  AMBULANCE_TRUST_AND_SERVICES: 'Ambulance Trust and Services',
  VOLUNTARY_ORGANISATIONS: 'Voluntary Organisations',
  CHARITABLE_ORGANISATIONS: 'Charitable Organisations',
  LOCAL_AUTHORITY_AND_SERVICES: 'Local Authority and Services',
  INTEGRATED_CARE_BOARD: 'Integrated Care Board',
  NHS_ENGLAND: 'NHS England',
  EDUCATION_ORGANISATION: 'Education Organisation',
  OTHER_PLEASE_STATE: 'Other - please state',
  I_AM_NOT_IN_WORK: 'I am not in work'
};

export const organisationSizes = {
  EMPLOYEES_1_TO_10: '1-10 employees',
  EMPLOYEES_11_TO_50: '11-50 employees',
  EMPLOYEES_51_TO_200: '51-200 employees',
  EMPLOYEES_201_TO_500: '201-500 employees',
  EMPLOYEES_501_TO_1000: '501-1,000 employees',
  EMPLOYEES_1001_TO_5000: '1,001-5,000 employees',
  EMPLOYEES_5000_PLUS: '5,000+ employees'
};

export const otherLocationWording = 'please specify below';
export const organisationLocations = {
  COUNTY_DURHAM: 'County Durham',
  NORTHUMBERLAND: 'Northumberland',
  DARLINGTON: 'Darlington',
  REDCAR_AND_CLEVELAND: 'Redcar & Cleveland',
  GATESHEAD: 'Gateshead',
  STOCKTON_ON_TEES: 'Stockton-on-Tees',
  HARTLEPOOL: 'Hartlepool',
  SOUTH_TYNESIDE: 'South Tyneside',
  MIDDLESBROUGH: 'Middlesbrough',
  SUNDERLAND: 'Sunderland',
  NEWCASTLE: 'Newcastle',
  NORTH_AND_WEST_CUMBRIA: 'North & West Cumbria',
  NORTH_TYNESIDE: 'North Tyneside',
  OTHER: 'Other'
};

export default modulesCategories;
